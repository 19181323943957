import * as angular from 'angular';

'use strict';

angular.module('Module1UserManagementService')
       .factory('Module1UserManagement', Module1UserManagement)
       .config(Module1UserManagementConfig);

Module1UserManagement.$inject = ['$http', 'Configuration'];

function Module1UserManagement($http, Configuration) {

    let apiUrl = Configuration.getApiUrl();

    return {
        getPagesForRoles: function getPagesForRoles(id) {
            let apiBase = apiUrl + 'get-pages-for-roles';
            let config = {
                params: {
                    userID: id
                }
            };
            return $http.get(apiBase, config);
        },
        getUsers: function getUsers() {
            let apiBase = apiUrl + 'manage-users';
            let config = {};
            return $http.get(apiBase, config);
        },
        getUser: function getUser(id) {
            let apiBase = apiUrl + 'manage-users/' + id;
            let config = {
                params: {id: id}
            };
            return $http.get(apiBase, config);
        },
        editUser: function editUser(editUserData) {
            let apiBase = apiUrl + 'manage-users/' + editUserData.id + '/edit';
            let config = {
                params: {
                    editUserData: editUserData
                }
            };
            return $http.get(apiBase, config);
        },
        editPassword: function editPassword(id, password) {
            let apiBase = apiUrl + 'manage-users/' + id + '/change-password';
            let config = {};
            let data = {
                password: password
            };
            return $http.post(apiBase, data, config);
        },
        addUser: function addUser(newUserData) {
            let apiBase = apiUrl + 'add-user';
            let config = {};
            let data = {
                name: newUserData.name,
                username: newUserData.username,
                email_address: newUserData.email_address,
                password: newUserData.password,
                generic_user: newUserData.generic_user,
                account_owner: newUserData.account_owner,
                profile_filter: newUserData.profile_filter,
                roles: newUserData.roles,
                customerName: newUserData.customerName,
                canLogInAutomatically: newUserData.canLogInAutomatically,
                is_user: newUserData.is_user,
                roleGroups: newUserData.roleGroups,
                sendNotification: newUserData.sendNotification
            };
            return $http.post(apiBase, data, config);
        },
        addToInstance: function addToInstance(newUserData) {
            let apiBase = apiUrl + 'add-to-instance';
            let config = {};
            let data = {
                username: newUserData.username,
            };
            return $http.post(apiBase, data, config);
        },
        deleteUser: function deleteUser(userID) {
            let apiBase = apiUrl + 'manage-users/' + userID;
            let config = {};
            let data = {
                userID: userID
            };
            return $http.delete(apiBase, data, config);
        },
        getRoleGroupsForUser: async function getRoleGroupsForUser(userId) {
            let apiBase = apiUrl + 'get-role-groups-for-user';
            let config = {
                params: {
                    userId : userId
                }
            };
            return $http.get(apiBase, config);

        }
    }
}

function Module1UserManagementConfig($compileProvider){
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}
