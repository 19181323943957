import * as angular from 'angular';

'use strict';

angular.module('focusMeModule', [])
    .directive('focusMe', FocusMeDirective);

FocusMeDirective.$inject = ['$parse'];

function FocusMeDirective($parse) {
    return {
        link: function (scope, element, attrs) {
            let model = $parse(attrs.focusMe);

            scope.$watch(model, function (value) {
                if (value === true) {
                    element[0].focus();
                }
            });

            element.bind('blur', function () {
                scope.$apply(model.assign(scope, false));
            });
        }
    };
}
