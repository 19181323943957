import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

// Css /////////////////////////////////////////
import './css'

// Modules /////////////////////////////////////
import './legacy/modules'
import './components/modules'

// Exception Services ///////////////////////////
import './components/exceptions/import'

// Services & directives ////////////////////////
import './legacy/services'
import './components/services'
import './legacy/directives'

// filters  /////////////////////////////////////
import './legacy/filters'

// Controllers //////////////////////////////////
import './legacy/controllers'
import './components/controllers'

// components ///////////////////////////////////
import './components/components'

// Boostrap
import './components/bootstrap.routes'
import './components/routes'
import './components/angular.downgrades'
import * as angular from "angular";
/////////////////////////////////////////////////
