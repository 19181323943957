// Loading all Legacy modules
import './app.module'

// Dependency for other modules
import './AuthService.module'
import './UserService.module'
import './DateService.module'

// No dependencies modules
import './EnterDataService.module'
import './ManagerService.module'
import './CustomerCtrl.module'
import './ManagerCtrl.module'
import './scanStationCtrl.module'
import './scanStationService.module'
import './Module1UserManagementCtrl.module'
import './Module1UserManagementService.module'
import './complianceCtrl.module'
import './complianceService.module'

// Those we are using the dependencies loaded before
import './MainCtrl.module'
import './AuthCtrl.module'
import './UserCtrl.module'
import './EnterDataCtrl.module'