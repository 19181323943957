import * as angular from 'angular';

'use strict';

angular.module('AuthCtrl')
    .controller('AuthController', AuthController);

AuthController.$inject = ['$auth', '$location', '$state', 'Auth', 'User', 'Data',
    '$window', '$interval', 'Configuration', '$scope', 'TwoFactorAuthentication',
    'LoginExceptions'
];

interface Credentials {
    email: string;
    password: string;
    autoLoginAttempt?: boolean;
}

function AuthController($auth, $location, $state, Auth, User, Data,
    $window, $interval, Configuration, $scope, TwoFactorAuthentication,
    LoginExceptions
) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            let version = Configuration.getVersion();
            let subDomain = Configuration.getSubDomain();
            let country = '';
            let flag = '';

            vm.version = version
            vm.subDomain = subDomain;

            vm.versionUpdateRequiredPopUp = 'closed';
            vm.country = country;
            vm.flag = flag;
            vm.authstate = $state.current.name;
            vm.loggedOutDueToInactivity = ($state.params.inactivitylogout == 'true');
            vm.is2FARequired = false;
            vm.failedCode = false;
            vm.codeResent = false;
            vm.tokenOfSignInWithGoogle = $location.search().token;
            vm.isSignInWithGoogle = false;
            vm.googleClient_id = '';
            vm.checkGoogleToken();
            await vm.checkSignInWithGoogle();

            await vm.compareVersion();
            vm.autologinParam = $location.search().autologin;
            await vm.checkAutoLogin();


        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This asynchronous function checks if the auto login parameter is set, and attempts to log in automatically if so.
     *
     * @method checkAutoLogin
     * @memberOf vm
     *
     * Behavior:
     * If `vm.autologinParam` is set, it will assign its value to `vm.email` and trigger an attempt for auto-login by calling the `vm.login()` function.
     *
     * Use:
     * It should be used when there's a possibility that auto login parameters are provided and user login can be automated.
     *
     * Errors:
     * Any errors encountered during the execution are logged to the console. This could be due to issues in `vm.login()`.
     *
     * Note:
     * - This function modifies `vm.email` and `vm.attemptAutoLogin`.
     * - It does not return anything.
     */
    vm.checkAutoLogin = async function () {
        try {
            if (vm.autologinParam) {
                vm.email = vm.autologinParam;
                vm.attemptAutoLogin = true;
                await vm.login();
            }
        } catch (e) {
            console.log(e);
        }
    }

    vm.checkSignInWithGoogle = async function () {
        try {

            const appSettingsExists = await vm.loadAppSettings();

            if (!appSettingsExists) {
                let settingsGoogle = await Auth.getSignInWithGoogleSettings();

                if (settingsGoogle) {
                    angular.forEach(settingsGoogle.data, function (setting) {
                        if (setting.setting_key == 'sign_in_with_google') {
                        vm.isSignInWithGoogle = Boolean(Number(setting.setting_value));
                    }
                    if (setting.setting_key == 'client_id') {
                        vm.googleClient_id = setting.setting_value;
                    }
                    });
                }
            }

        } catch (e) {
            console.log(e);
        }
    }


    vm.checkGoogleToken = function () {
        if (vm.tokenOfSignInWithGoogle) {
            vm.signInWithGoogle();
        }
    }

    vm.signInWithGoogle = function() {
        $window.localStorage.setItem('satellizer_token', vm.tokenOfSignInWithGoogle);
        vm.procceedWithLogin();
    }

    vm.getUsers = async function () {
        try {
            await User.getUsers();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is doing the logout steps
     * for a blocworx user.
     */
    vm.logoutAction = async () => {
        try
        {
            // Log out if on logout page
            if (vm.authstate == 'logout') {

                $window.localStorage.removeItem('redirect');
                vm.loggedMessage = 'Logging Out...';
                vm.token = $window.localStorage.getItem('satellizer_token');

                if (vm.token != null) {
                    await Auth.logout(vm.token);
                    $window.localStorage.removeItem('userRoles');
                    $window.localStorage.removeItem('userID');
                    $window.localStorage.removeItem('isCustomer');
                    $window.localStorage.removeItem('isSuperUser');
                    $window.localStorage.removeItem('cartolyticsCustomerShowOptions');
                    $window.localStorage.removeItem('translatedWords');
                    sessionStorage.clear();
                    $auth.logout();
                    window.location.reload();

                } else {
                    vm.loggedMessage = 'You are now logged out!';
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function is triggered on the main password
     * input that is checking gor the key-press event,
     * and for the login part if we press enter (13), this
     * will be triggering the login action.
     *
     * @param e
     */
    vm.triggerAuthLogin = async function (e) {
        try {
            if (e.keyCode == 13) {
                await vm.login();
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is the main login function, this will be checking a few things
     * before login, good things to know is we are able to login
     * with or without 2 factor authenticator, and for those cases please check the
     * src/views/authView.html.
     *
     * Main of the rules you will need to check for the variables into the template file
     * you will see that:
     *
     * On normal login we use those
     * is2FARequired
     * failedLogin
     * loginError
     *
     * On 2FA we use:
     * failedCode
     * is2FARequired
     * codeError
     * codeResent
     *
     */
    vm.login = async function () {
        try {
            vm.wrongDetails = false;

            let credentials: Credentials = {
                email: vm.email,
                password: vm.password
            }

            if(vm.attemptAutoLogin){
                credentials.autoLoginAttempt = vm.attemptAutoLogin
            }

            // step 1 : check if the user exist
            const existUser = await TwoFactorAuthentication.existUser(vm.email);

            // Step 2 : If does not exist a user credential on the table User, must thrown an exception
            if(existUser == false){
                LoginExceptions.throwDoesNotExistUserException();
            }

            // Step 3: now the user is validated, and exist, we check for the 2FactorAuthenticator
            const is2FARequired = await TwoFactorAuthentication.isRequired(vm.email);
            if(is2FARequired) {

                // Step 3.1 = Checking if the credentials are valid
                const isValidCredentials = await TwoFactorAuthentication.validateCredentials(
                    vm.email, vm.password
                );

                // Step 3.2 = throw an exception if we have invalid credentials
                if(isValidCredentials == false){
                    LoginExceptions.throwInvalidCredentialsException();
                }

                vm.is2FARequired = true;

                // note: as this have an extra step, we do not need to have the vm.proceedWithLogin
                // as it is being doing that on verify2FA function, that is the function that validates
                // the number and over there is doing the vm.proceedWithLogin()
                await $auth.login(credentials);

            } else {

                // Normal Login Without 2 Factor Authenticator
                await $auth.login(credentials)
                await vm.procceedWithLogin();
            }

        } catch (exception) {

            console.log(exception);
            vm.loginError = Auth.getLoginError(exception);
            vm.failedLogin = true;

        } finally {
            $scope.$apply();
        }

    }

    /**
     * This will allow the user to proceed with the login action.
     */
    vm.procceedWithLogin = async function () {0
        try {

            let data2 = await Data.getAppSettings();

            $window.localStorage.setItem('appSettings', JSON.stringify(data2.data));
            let redirect = '/';

            if ($window.localStorage.getItem('redirect') != null && $window.localStorage.getItem('redirect') != '/') {
                redirect = $window.localStorage.getItem('redirect');
                $window.localStorage.removeItem('redirect');
            } else {
                angular.forEach(data2.data.data, function (value, index) {
                    if (value.setting_key == 'redirect') {
                        redirect = value.setting_value;
                    }
                });
            }

            $window.location.href = redirect;

        } catch (e) {
            throw e
        }
    }

    /**
     * This is the function that is checking the Two factor authentication
     * @param code
     */
    vm.verify2FA = async function (code: string) {
        try
        {
            const isValid = await TwoFactorAuthentication.verifyCode(code);
            if(!isValid){
                vm.failedCode = true;
                LoginExceptions.throwWrong2FACodeException();
            }

            // If everything is validated we can proceed with the login
            vm.is2FARequired = false;
            await vm.procceedWithLogin();

        }
        catch (exception) {
            console.log(exception);
            vm.codeError = Auth.getLoginError(exception);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This is a function that re-send the code for the
     * 2 factor authentication.
     */
    vm.resendCode = async function () {
        try {
            vm.codeResent = true;
            vm.failedCode = false;
            vm.codeError = 'Code Sent';
            await TwoFactorAuthentication.resendCode(vm.email);
        } catch (e) {
            console.log(e);
            vm.loginError = Auth.errorMessage;
        }
        finally {
            $scope.$apply();
        }
    }

    vm.loadAppSettings = async function () {
        try {
            let exists = false;

            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            if (appSettingParsed) {
                vm.appSettings = appSettingParsed;
                angular.forEach(appSettingParsed.data, function (settingEntry) {
                    if (settingEntry.setting_key == 'sign_in_with_google') {
                        vm.isSignInWithGoogle = Boolean(Number(settingEntry.setting_value));
                    }
                    if (settingEntry.setting_key == 'client_id') {
                        vm.googleClient_id = settingEntry.setting_value;
                        exists = true;
                    }
                })
            }

            return exists;

        } catch (error) {
            console.log(error);
        }
    }


    /**
     * This is verifying if the version are equal or
     * it is necessary to update the version to get
     * updates from deployments.
     *
     **/

    vm.compareVersion = async function () {
        try
        {
            await Auth.compareVersions(vm.version);
            vm.versionUpdateRequired = false;

        } catch (e) {
            console.log(e);
            vm.versionUpdateRequiredPopUp = 'open';
            vm.versionUpdateRequired = true;
            vm.versionUpdateMessage = e.data.error;
        }
    }

}
