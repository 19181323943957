import * as angular from 'angular';
import * as moment from "moment";
import * as $ from "jquery";

import SignaturePad from 'signature_pad/src/signature_pad';

'use strict';

angular.module('EnterDataCtrl')
    .controller('EnterDataController', EnterDataController);

EnterDataController.$inject = ['$scope', 'Data', 'Auth', '$state', '$templateCache',
    '$window', '$interval', '$sce', 'Configuration', 'compliance', 'Validator', 'MessageService'];

function EnterDataController($scope, Data, Auth, $state, $templateCache, $window,
                             $interval, $sce, Configuration, compliance, Validator, MessageService) {

    let vm = this;

    vm.$onInit = async function () {
        $templateCache.removeAll();
        vm.validator = Validator;
        vm.editAutomatedReport = [];
        vm.apiUrl = Configuration.getApiUrl();
        vm.subDomain = Configuration.getSubDomain();
        vm.domain = Configuration.getDomain();
        vm.stateName = $state.current.name;
        vm.stateUniqueModuleSlug = $state.params.uniqueModuleSlug;
        vm.dataFilteringData = {}
        vm.metaDataFilters = {}
        vm.id = $state.params.id;
        vm.sidebarHighlighter = '';
        vm.showImportModule = false;
        if (typeof ($state.current.params) != 'undefined') {
            vm.sidebarHighlighter = $state.current.params.sidebarHighlighter;
        }
        vm.userID = localStorage.getItem('userID');
        vm.auditData = {}
        vm.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');

        // RMA Admin Files for Admin panel
        vm.rmaAdminFiles = [];
        vm.loadingData = [];

        if (vm.stateName == 'job-module' || vm.stateName == 'module-setup' || vm.stateName === 'module-edit-forms' || vm.stateName === 'bloc') {
            let refresh = true;
            vm.id = vm.jobID = await Data.getStaticJobID($state.params.uniqueModuleSlug, refresh);
            await vm.getUsersStations();
            await vm.triggerGetJob();

        } else if (vm.stateName != 'auth' && vm.stateName != 'logout' && vm.stateName != 'home') {
            await vm.getUsersStations();
        }


        vm.dataCriteria = {
            jobID: undefined,
            stationID: undefined,
            station: {},
        }

        vm.liveDisplayData = {
            XAxis: "",
            YAxis: "",
            fieldData: "",
            countOption: "0",
            day: "",
        }

        vm.sortByOrder = 'ASC';

        await vm.getJob();

        if (vm.stateName == 'edit-job-module' || vm.stateName == 'upload-sop' || vm.stateName == 'scan-station-edit' || vm.stateName == 'edit-bloc') {
            await vm.getAllUsers();
            await vm.getUploadedSOPFiles();
        }

        if (vm.stateName == 'job-setup' || vm.stateName == 'edit-job-module' || vm.stateName == 'create-scan-stations' || vm.stateName == 'module-edit-forms' || vm.stateName == 'scan-stations'
            || vm.stateName == 'stations-setup' || vm.stateName == 'reporting-audit' || vm.stateName == 'job-reports'
            || vm.stateName == 'training-records-edit' || vm.stateName == 'job') {
            await vm.getScanStations();
        }

        if (vm.stateName == 'language') {
            await vm.getLanguageSettings();
        }

        if (vm.stateName == 'sign-on-off') {
            await vm.getAllUsers();
            await vm.getAntiStaticUserLogs();
        }

        if (vm.stateName != 'auth') {
            await vm.getAllUsers();
        }

        if (vm.stateName != 'auth') {
            await vm.getJobUsers();
        }

        if (vm.stateName == 'digital-signature') {
            await vm.getDigitalSignaturePreview();
        }

        $scope.$on('savingDataBroadcast', function () {
            vm.message = Data.message;
        });

        $scope.$on('updatePageNumber', function () {
            vm.dataFilteringData.currentPage = Data.updatedData;
        });

        $scope.$on('updateResultLimit', function () {
            vm.dataFilteringData.resultLimit = Data.updatedData;
        });

        $scope.$on('originalDataForDataFiltering', async function () {
            vm.originalDataForDataFiltering = Data.updatedData;
        });

        $scope.$on('updateIDs', function () {
            vm.dataFilteringData.stationID = Data.updatedData[0];
            vm.dataFilteringData.jobID = Data.updatedData[1];
        });

        $scope.$on('updateDataForDataFiltering', function () {
            vm.dataForDataFiltering = Data.updatedData;
            vm.dataFilteringData = Data.updatedData;
        })

        $scope.$apply();

    }

    vm.getUsersStations = async function () {
        try {
            vm.loadingStations = true;
            let data = await Data.getUsersStations(vm.id, vm.metaDataFilters, vm.stateName);

            vm.loadingStations = false;
            vm.scanStations = data.data.data;
            vm.sections = data.data.sections;
            vm.originalSections = data.data.originalSections;
            vm.alternativeDisplay = data.data.alternativeDisplay;
            vm.stationInOriginalSectionCount = [];
            vm.alternativeStationInSectionCount = [];
            vm.stationInSectionCount = [];

            if (data.data.complianceForms.length > 0) {
                vm.complianceForms = data.data.complianceForms;
            }

            if (vm.alternativeDisplay == true) {
                angular.forEach(vm.sections, function (value, index) {
                        vm.alternativeStationInSectionCount[value] = 0;
                    }
                );
            } else {
                angular.forEach(vm.sections, function (value, index) {
                        vm.stationInSectionCount[value.id] = 0;
                    }
                );
            }

            vm.stationInOriginalSectionCount = [];
            angular.forEach(vm.scanStations, function (value, index) {
                    if (value.alternative_section == 'Other') {
                        if (typeof (vm.stationInOriginalSectionCount[value.section_id]) == 'undefined') {
                            vm.stationInOriginalSectionCount[value.section_id] = 0;
                        } else {
                            vm.stationInOriginalSectionCount[value.section_id]++;
                        }
                    }
                }
            );

            angular.forEach(vm.scanStations, function (value, index) {
                if (value.section_id == null) {
                    vm.unassigedStations = true;
                } else {
                    vm.stationInSectionCount[value.section_id]++;
                }
                if (vm.alternativeDisplay == true) {
                    vm.alternativeStationInSectionCount[value.alternative_section]++;
                }
                if (value.requires_anti_static == 1) {
                    vm.requiresAntiStatic = 1;
                }

            });
            vm.accessFailed = false;
            $scope.$apply();

        } catch (e) {
            console.log(e);
            if (typeof (e.data.status) != 'undefined' && e.data.status === 401) {
                vm.accessFailed = true;
            }
            vm.loadingStations = false;
        }

    }

    vm.alert = function (alertMessage) {
        alert(alertMessage);
    }

    vm.addRmaAdminFile = function () {
        try {
            vm.rmaAdminFiles.push({path: null});
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteRmaAdminFile = function (index) {
        try {
            vm.rmaAdminFiles.splice(index, 1);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.saveRmaAdminFiles = async function (rmaId) {
        try {
            let data = await Data.saveRmaAdminFiles(rmaId, vm.rmaAdminFiles);
            if (!data.data.success) {
                alert(data.data.message);
                return;
            }
            vm.rmaAdminFiles = [];
            await vm.getRMAs(vm.rmaSearch, vm.offSet);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addUnitType = function (rma) {
        try {
            rma.units.push({quantity: 0, unit_type: "", unit_price: 0});
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteUnit = function (rma, index) {
        try {
            rma.units.splice(index, 1);
        } catch (e) {
            console.log(e);
        }

        $scope.$apply();

    }

    vm.updateUnitType = function (unit) {
        try {
            let unitTypeName = unit.unit_type;
            for (let i = 0; i < vm.unitTypes.length; i++) {
                if (vm.unitTypes[i].name == unitTypeName) {
                    unit.unit_price = vm.unitTypes[i].price;
                }
            }
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }


    vm.setActiveStation = function () {
        try {
            let tmpStation = {}

            for (let i = 0; i < vm.scanStations.length; i++) {
                if (vm.scanStations[i].id == vm.dataCriteria.stationID) {
                    tmpStation = vm.scanStations[i];
                }
            }
            vm.dataCriteria.station = tmpStation;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getAllUsers = async function () {
        try {
            let data = await Data.getAllUsers();
            vm.allUsers = data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.createJob = async function () {
        try {
            if (typeof (vm.createJobData.unitRate) == 'undefined'
                || typeof (vm.createJobData.quantity) == 'undefined') {
                alert('Please make sure the Unit Rate and Quantity are numbers');
            } else {
                await Data.createJob(vm.createJobData);
                angular.forEach(vm.createJobData, function (value, index) {
                    vm.createJobData[index] = '';
                });

                // Calls to update the job list after creating new job from job-stations-builder
                // Sort by descending, this way we see the latest job appear once its created

                vm.sortByOrder = 'DESC';
                await vm.getUsersJobs('created_at', vm.jobTypeToFilterBy);
                vm.createNewJob = false;
            }
            $scope.$apply();

        } catch (e) {
            console.log(e);
            alert('Please add a Module Title before continuing');
        }

    }

    vm.updateJob = async function () {
        try {
            if (typeof (vm.jobData.unit_rate) == 'undefined' || typeof (vm.jobData.quantity) == 'undefined') {
                alert('Please make sure the Unit Rate and Quantity are numbers');
            } else {
                if ((typeof vm.jobData.customer_number) === "string" || vm.jobData.customer_number === undefined) {
                    vm.jobData.customer_number = vm.jobData.customer_id;
                }
                if (vm.jobData.reference_po === null || vm.jobData.reference_po === 'null') {
                    vm.jobData.reference_po = " ";
                } else if (vm.jobData.reference_po === '') {
                    vm.jobData.reference_po = vm.jobData.reference_po;
                }

                await Data.updateJob(vm.jobData, vm.id);
                alert('Module Successfully Updated');
                await vm.getJob();
            }
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getUserSignedData = async function (itemSigned) {
        try {
            vm.itemSigned = itemSigned;
            let data = await Data.getUserSignedData(vm.id, itemSigned);
            vm.userSignedData = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.signItem = async function (userID, password, itemToSign) {
        try {
            await Data.signItem(vm.id, userID, password, itemToSign);
            await vm.getUserSignedData(vm.itemSigned);
            $scope.$apply();
        } catch (e) {
            alert('Incorrect Password');
            console.log(e);
        }
    }

    vm.signItemFrontEnd = async function (userID, password, itemToSign) {
        try {
            await Data.signItem(vm.id, userID, password, itemToSign);
            // vm.getAntiStaticSignedForUser();
            $scope.$apply();
        } catch (e) {
            alert('Incorrect Password');
            console.log(e);
        }

    }

    vm.getUsersJobs = async function (sortBy, jobTypeToFilterBy) {
        try {
            vm.sortBy = sortBy;
            if (typeof (vm.resultLimit) == 'undefined') {
                vm.resultLimit = 10;
            }
            if (typeof (vm.currentPage) == 'undefined') {
                vm.currentPage = 1;
            }

            let data = await Data.getUsersJobs(vm.sortBy, vm.master, vm.sortByOrder, jobTypeToFilterBy,
                vm.resultLimit, vm.currentPage, vm.stateName);

            vm.jobList = data.data.data.jobs;
            vm.upperLimit = data.data.data.total;
            if (vm.keepASCD_DESC_Settings == false) {
                if (vm.sortByOrder === 'ASC') {
                    vm.sortByOrder = 'DESC';
                } else {
                    vm.sortByOrder = 'ASC';
                }
            }

            vm.keepASCD_DESC_Settings = false;
            if (typeof (vm.jobTypeToFilterBy) === 'undefined' && typeof vm.jobList[0] != 'undefined') {
                vm.jobTypeToFilterBy = vm.jobList[0]['activity_status'];
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getJobs = async function (status, sortBy) {
        try {
            let data = await Data.getJobs(status, sortBy, vm.master);
            vm.jobList = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.triggerGetJob = async function () {
        try {
            let data = await Data.getJob(vm.id);
            vm.jobData = data.data.data[0];
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getJob = async function () {
        try {
            if (typeof (vm.id) != 'undefined') {
                let data = await Data.getJob(vm.id);
                vm.jobData = data.data.data[0];
                vm.accessFailed = false;
                $scope.$apply();
            }


        } catch (e) {
            console.log(e);
            if (typeof (e.data.status) != 'undefined' && e.data.status == 401) {
                vm.accessFailed = true;
            }
        }
    }

    /**
     * This is starting the process of import scan stations from another module
     * to the current module.
     *
     * @param moduleIdToImportBlocFrom
     */
    vm.importAllScanStationsFromAnotherModule = async function (moduleIdToImportBlocFrom, moduleIdToImportTo, userId) {
        try
        {

            vm.waitingForModuleImport = true;

            // making sure that we have a job id before copy data
            if(moduleIdToImportBlocFrom == null){
                throw new Error("Missing Select Job to duplicate");
            }

            // making sure that we have a job id before copy data
            if(moduleIdToImportTo == null){
                throw new Error("Missing where to copy to, contact Blocworx");
            }

            // making sure that we have a user id before copy data
            if(userId == null){
                throw new Error('Missing user ID, contact Blocworx');
            }

            // loading the import of scan stations from the job_id
            let response = await Data.importAllScanStationsFromAnotherModule(moduleIdToImportBlocFrom, moduleIdToImportTo, userId);

            // triggering the success from it
            MessageService.addSuccessMessage('Module Successfully Imported');

            // reload data
            await vm.getUsersStations();
            await vm.triggerGetJob();
            vm.waitingForModuleImport = false;

        } catch (exception) {
            MessageService.addErrorMessage(exception?.message
                ? exception.message
                : 'Something went wrong with the import, please contact support@blocworx.com.'
            );
            vm.waitingForModuleImport = false;
        }
    }

    vm.openCloseJob = async function () {
        try {
            let statusUpdateTo;
            if (vm.jobData.status == 1) {
                statusUpdateTo = 0;
            } else {
                statusUpdateTo = 1;
            }
            await Data.openCloseJob(vm.id, statusUpdateTo);
            vm.jobData.status = statusUpdateTo;
            if (statusUpdateTo == 0) {
                $window.location.href = '/admin';
            } else {
                $window.location.href = '/admin/closed-jobs';
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateJobStatus = async function (id = null, status = null) {
        try {
            if (id === null) {
                id = vm.id;
            }
            if (status === null) {
                status = vm.jobData.activity_status;
            }
            await Data.updateJobStatus(id, status);
            alert('Category Successfully Updated');
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.addRemoveTemplate = async function (templateStatus) {
        try {
            await Data.addRemoveTemplate(vm.id, templateStatus);
            vm.jobData.template = templateStatus;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getStationCounts = async function (jobID) {
        try {
            let data = await Data.getStationCounts(jobID);
            vm.stationCounts = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getStationCountsForCustomer = async function (jobID) {
        try {
            let data = await Data.getStationCountsForCustomer(jobID);
            vm.stationCounts = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.archiveJob = async function () {
        try {
            await Data.archiveJob(vm.id);
            $window.location.href = '/admin/closed-jobs';

        } catch (e) {
            console.log(e);
        }
    }

    vm.randomLogoAppend = Math.random() * 100;
    vm.uploadSOPPage = async function () {
        try {
            let file = vm.sopFile;
            vm.pleasewait = true;

            let data = await Data.uploadSOPPage(vm.sopPageTitle, file, vm.id);
            await vm.getUploadedSOPFiles();
            alert('File uploaded');
            vm.pleasewait = false;
            $scope.$apply();
        } catch (e) {
            console.log(e);
            vm.pleasewait = false;
        }
    }

    vm.deleteSOPFile = async function (id) {
        try {
            await Data.deleteSOPFile(id);
            await vm.getUploadedSOPFiles();
            alert('File deleted');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Something went wrong, please make sure you have resaved the nightline file and that there are ' +
                'no empty cells in the Customer Ref section');
        }

    }

    vm.getUploadedSOPFiles = async function () {
        try {
            let data = await Data.getUploadedSOPFiles(vm.id);
            vm.uploadedSOPFiles = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This function is tasked with retrieving all stations
     * associated with a job.
     *
     * @param jobID
     * @param sectionID
     */
    vm.getJobScanStations = async function (jobID, sectionID) {
        try {

            // loading the data related to all scan station of this jobID
            let data = await Data.getScanStations(jobID, sectionID);
            vm.scanStations = data.data.data;

            // identifying the unassigned scan station, that rule says section_id is null
            angular.forEach(vm.scanStations, function (value, index) {
                if (value.section_id == null) {
                    vm.unassigedStations = true;
                }
            });

            // getting the scan stations from the data object
            vm.sections = data.data.sections;

            // If the station is selected (dataCriteria.stationID is not undefined),
            // we set the active station in dataCriteria.station
            vm.setActiveStation();
            vm.accessFailed = false;
            $scope.$apply();

        } catch (e) {

            console.log(e);
            vm.unassigedStations = false;
            if (typeof (e.data.status) != 'undefined' && e.data.status == 401) {
                vm.accessFailed = true;
            }
        }

    }

    vm.getScanStations = async function () {
        try {
            await vm.getJobScanStations(vm.id, null);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.addNewScanStation = async function () {
        try {
            await Data.addNewScanStation(vm.id, vm.newScanStationName, vm.newScanStationSectionID, vm.newScanStationIcon);
            await vm.getScanStations();
            vm.newScanStationSectionID = null;
            $scope.$apply();
        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.addNewScanStationSection = async function () {
        try {
            await Data.addNewScanStationSection(vm.id, vm.newSectionName);
            await vm.getScanStations();
            $scope.$apply();
        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }


    vm.reorderSections = async function (direction, sectionId) {
        await Data.reorderSections(direction, sectionId, vm.jobData.id);
        await vm.getScanStations();
        $scope.$apply();

    }

    vm.updateScanStationOrderID = async function (stationID, direction) {
        try {
            await Data.updateScanStationOrderID(stationID, direction);
            await vm.getScanStations();
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteScanStationSection = async function (sectionID) {
        try {
            await Data.deleteScanStationSection(sectionID);
            await vm.getScanStations();
            alert('Section deleted');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Error');
        }

    }

    vm.deleteScanStation = async function (scanStationID) {
        try {
            await Data.deleteScanStation(scanStationID);

            if (vm.stateName == 'create-scan-stations' || vm.stateName == 'edit-job-module' || vm.stateName == 'job-setup') {
                await vm.getScanStations();
            } else if (vm.stateName == 'job' || vm.stateName == 'training-records' || vm.stateName != 'home') {
                await vm.getUsersStations();
            }
            $scope.$apply();
        } catch (e) {
            alert(e.data.error);
        }

    }

    vm.getLanguageSettings = async function () {
        try {
            let data = await Data.getLanguageSettings(vm.id);
            vm.languageSettings = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.updateLanguageSettings = async function () {
        try {
            await Data.updateLanguageSettings(vm.id, vm.localLanguageOfStaff, vm.SOPTranslated, vm.SOPUpdateRequired);
            await vm.getLanguageSettings();
            $scope.$apply();
        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }


    vm.getDigitalSignatures = async function () {
        try {
            let data = await Data.getDigitalSignatures(vm.id, vm.signOffItem);
            vm.digitalSignatures = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Something went wrong, please make sure you have resaved the nightline file and that there are no ' +
                'empty cells in the Customer Ref section');
        }

    }

    vm.signInOut = async function (key, userID, signInPassword, signInOutStatus) {
        try {
            await Data.signInOut(userID, signInPassword, signInOutStatus);
            await vm.getAllUsers();
            vm.signInPassword[key] = '';
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Incorrect Password');
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.getAntiStaticUserLogs = async function () {
        try {
            let data = await Data.getAntiStaticUserLogs();
            vm.antiStaticUserLogs = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.logAntiStaticEntry = async function (key, userID, signInPassword) {
        try {
            await Data.logAntiStaticEntry(userID, signInPassword);
            await vm.getAntiStaticUserLogs();
            vm.signInStrapPassword[key] = '';
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Incorrect Password');
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.getJobUsers = async function () {
        try {
            let data = await Data.getJobUsers(vm.id);
            vm.jobUsers = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.addJobUser = async function (selectedUserID) {
        try {
            await Data.addJobUser(vm.id, selectedUserID);
            await vm.getJobUsers();
            $scope.$apply();
        } catch (e) {
            console.log(e);
            if (typeof (e.data.error) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error perhaps some of your data is not entered?';
            }
        }

    }

    vm.deleteUserFromJob = async function (userToJobID) {
        try {
            await Data.deleteUserFromJob(userToJobID);
            await vm.getJobUsers();
            alert('User deleted');
            $scope.$apply();

        } catch (e) {
            console.log(e);
            alert('Error');
        }

    }

    vm.addDigitalSignature = async function () {
        try {
            await Data.addDigitalSignature(vm.id, vm.userID, vm.accept().dataUrl, vm.signoffItem);
            await vm.getDigitalSignaturePreview();
            alert('Thanks, you have succesfully updated your signature');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Error has occured, please check browser console to view error');
        }

    }


    vm.getDigitalSignaturePreview = async function () {
        try {
            let data = await Data.getDigitalSignaturePreview(vm.userID);
            vm.digitalSignaturePreview = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
            vm.digitalSignaturePreview = '';
            $scope.$apply();
        }

    }

    vm.downloadBlocFiles = async (stationID: number, dataFilteringData: string, jobID: number) => {
        try {

            vm.downloadingBlocFiles = true
            const data = await Data.downloadBlocFiles(stationID, dataFilteringData, jobID)

            let blob = data.data;
            let zipBlob = new Blob([blob], {type: "application/zip"});
            let url = $window.URL || $window.webkitURL;
            vm.fileUrl = url.createObjectURL(zipBlob);

            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = `files-${stationID}-download.zip`

            a.click();
            vm.downloadingBlocFiles = false

        } catch(e) {
            console.log(e)
            alert('An Error has occurred. Please contact the administrator.');
        } finally {
            $scope.$apply();
        }
    }

    vm.getDataReport = async function (stationID, stationName, fieldsToReport, responseType, infoPage, dataFilteringData) {
        try {
            vm.loadingReport = [];
            vm.loadingReport[stationID + 'data-report'] = true;
            vm.scanStationFieldTypes = [];
            let data = await Data.getDataReport(stationID, vm.requestedReportData, fieldsToReport, responseType,
                infoPage, dataFilteringData);

            angular.forEach(vm.stationToShow, function (value, index) {
                vm.scanStationFieldTypes[value.field_slug] = value.field_type;
            });

            vm.loadingReport = false;
            if (responseType == 'blob') {
                vm.openExcelFile(stationName, data.data);
            } else if (responseType == '') {
                if (typeof (data) != 'undefined') {
                    $('html, body').animate({
                        scrollTop: "0px"
                    }, 800);
                    vm.dataToReportOnScreen = data.data.data;
                }
            }
            $scope.$apply();

        } catch (e) {
            console.log(e);
            alert('An Error has occurred and this report has failed to generate. Please contact the administrator.');
        }

    }

    vm.generateAnectoStationReport = async function (stationID, stationName) {
        try {
            vm.loadingReport = [];
            let data = await Data.generateAnectoStationReport(stationID, vm.id);
            vm.loadingReport = false;
            vm.openDocFile(stationName, data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('An Error has occurred and this report has failed to generate. Please contact the administrator.');
        }

    }

    vm.getSummaryCountReport = async function (stationID, stationName, fieldsToReport) {
        try {
            vm.loadingReport = [];
            vm.loadingReport[stationID + 'summary-count'] = true;
            let data = await Data.getSummaryCountReport(stationID, vm.requestedReportData, fieldsToReport);
            vm.loadingReport = false;
            vm.openExcelFile(stationName + '-summary', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('An Error has occurred and this report has failed to generate. Please contact the administrator.');
        }

    }

    vm.generateUploadExcelFileTemplate = async function (stationId, stationName, scanStationFieldSlugs) {
        try {
            vm.loadingReport = true;
            vm.stationName = stationName;

            let data = await Data.generateUploadExcelFileTemplate(stationId, scanStationFieldSlugs);

            vm.loadingReport = false;

            vm.openExcelFile(vm.stationName, data.data);

            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    vm.openExcelFile = function (stationName, data) {
        try {
            let date = moment().format("-MMM-Do-YYYY");
            let url = $window.URL || $window.webkitURL;
            vm.fileUrl = url.createObjectURL(data);
            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = stationName + date + '.xlsx';
            document.body.appendChild(a);
            a.click();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.openDocFile = function (stationName, data) {
        try {
            let date = moment().format("-MMM-Do-YYYY");
            let url = $window.URL || $window.webkitURL;
            vm.fileUrl = url.createObjectURL(data);
            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = stationName + date + '.docx';
            document.body.appendChild(a);
            a.click();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    // REPORTING
    vm.getAntiStaticReport = async function () {
        try {
            vm.loadingReport = true;
            let data = await Data.getAntiStaticReport(vm.requestedReportData);

            vm.loadingReport = false;
            vm.openExcelFile('anti-static-report', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getSignedInOutReport = async function () {
        try {
            vm.loadingReport = true;
            let data = await Data.getSignedInOutReport(vm.requestedReportData);
            vm.loadingReport = false;
            vm.openExcelFile('signed-in-out-report', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getGeneralAuditReport = async function () {
        try {
            vm.loadingReport = true;
            let data = await Data.getGeneralAuditReport(vm.requestedReportData);

            vm.loadingReport = false;
            vm.openExcelFile('general-audit', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getUserSignedReport = async function () {
        try {
            vm.loadingReport = true;
            let data = await Data.getUserSignedReport(vm.id);

            vm.loadingReport = false;
            vm.openExcelFile('user-signed-report', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getJobDetailsReport = async function () {
        try {
            vm.loadingReport = true;
            let data = await Data.getJobDetailsReport(vm.id);

            vm.loadingReport = false;
            vm.openExcelFile('job-details', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.updateJobRule = async function (ruleSetting, value) {
        try {
            await Data.updateJobRule(vm.id, ruleSetting, value);
            alert('Anti Static Requirement rule updated');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Something went wrong, please make sure you have resaved the nightline file and that there are no ' +
                'empty cells in the Customer Ref section');
        }

    }

    vm.lookUpData = async function (e, dataToLookUp) {
        try {
            if ((e !== null && e.keyCode == 13) || e === null) {
                vm.loadingSearch = true;
                let data = await  Data.lookUpData(dataToLookUp, vm.jobToLookUp);
                vm.loadingSearch = false;
                vm.dataManagerData = data.data.data;
                if (vm.dataManagerData.length == 0) {
                    vm.noDataFound = true;
                }
                $scope.$apply();
            }
        } catch (e) {
            console.log(e);
            vm.loadingSearch = false;
            alert('Unknown Error');
        }

    }

    /**
     * This function will be responsible to update the data for
     * a scan station edit data in alert cases.
     *
     * @param entryID
     * @param newData
     * @param stationID
     * @param hideAlert this by default is a clean function, if it is the alert case
     * it will be containing things to hide the alert.
     */
    vm.updateData = async function (entryID, newData, stationID, afterUpdateFunction = () => {}) {
        try
        {
            // update the data
            await Data.updateData(entryID, newData, stationID);

            // if contains a function to run afterwards it will
            afterUpdateFunction();

            // update the lookup data searching for it again
            await vm.lookUpData(null, vm.dataToLookUp);

            // updating data on openUpdate
            angular.forEach(vm.openUpdate, function (value, index) {
                vm.openUpdate[index] = false;
            });

            vm.handleClick(false);

            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Unknown Error');
        }

    }

    vm.updateJobTitle = async function () {
        try {
            await Data.updateJobTitle(vm.jobData.title, vm.id);
            alert('Module Successfully Updated');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert(e);
        }
    }

    /**
     * This function will be responsible to delete a data from a scan station
     * delete cases.
     *
     * @param entryID
     * @param afterDeleteFunction this by default is a clean function, if it is the alert case
     * it will be containing things to hide the alert.
     */
    vm.deleteData = async function (entryID, afterDeleteFunction = () => {}) {
        try
        {
            // delete of the data
            await Data.deleteData(entryID);

            // if contains a function to run afterwards it will
            afterDeleteFunction();

            // update the lookup data searching for it again
            await vm.lookUpData(null, vm.dataToLookUp);

            // updating data on openUpdate
            angular.forEach(vm.openUpdate, function (value, index) {
                vm.openUpdate[index] = false;
            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
            alert('Unknown Error');
        }
    }

    vm.getCustomers = async function () {
        try {
            let data = await Data.getCustomers();
            vm.customers = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteCustomer = async function (customerID) {
        try {
            await Data.deleteCustomer(customerID);
            await vm.getCustomers();
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Unknown Error');
        }

    }

    vm.addCustomer = async function () {
        try {
            await Data.addCustomer(vm.newCustomerName);
            await vm.getCustomers();
            vm.newCustomerName = '';
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Unknown Error');
        }

    }

    vm.getCustomerData = async function () {
        try {
            if (typeof ($state.params.customerID) != 'undefined') {
                vm.customerID = $state.params.customerID;
            }

            let data = await Data.getCustomerData(vm.customerID);
            vm.customerData = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Unknown Error');
        }

    }

    vm.addGeneralAuditData = async function () {
        try {
            await Data.addGeneralAuditData(vm.auditData);

            angular.forEach(vm.auditData, function (value, index) {
                value.answer = null;
                value.comment = null;
            });

            await vm.getGeneralAuditData();
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Unknown Error');
        }

    }

    vm.getGeneralAuditData = async function () {
        try {
            let data = await Data.getGeneralAuditData();
            vm.generalAuditData = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.openSOPFile = async function (subDomain, jobID, fileName) {
        try {
            vm.loadingReport = true;
            let data = await Data.openSOPFile(subDomain, jobID, fileName);

            vm.loadingReport = false;
            vm.openFileLocally(fileName, data.data, 'new-tab');
            // vm.openFileLocally('anti-static-report', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getFileByPath = async function (path, fileName, targetOption) {
        try {
            vm.loadingData[fileName] = true;
            let data = await Data.getFileByPath(path);

            vm.loadingData[fileName] = false;
            vm.openFileLocally(fileName, data.data, targetOption);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getStationImage = async function () {
        try {
            vm.jobID = $state.params.jobID;
            vm.stationID = $state.params.stationID;
            vm.fileName = $state.params.fileName;
            let data = await Data.getStationImage(vm.subDomain, vm.jobID, vm.stationID, vm.fileName);

            vm.loadingReport = false;
            vm.showImageOnScreen(vm.fileName, data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getStationImageForCustomer = async function () {
        try {
            vm.jobID = $state.params.jobID;
            vm.stationID = $state.params.stationID;
            vm.fileName = $state.params.fileName;

            let data = await Data.getStationImageForCustomer(vm.subDomain, vm.jobID, vm.stationID, vm.fileName);
            vm.loadingReport = false;
            vm.showImageOnScreen(vm.fileName, data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
            vm.imageLoadFailed = true;
        }

    }

    vm.getJobsForSOPs = async function (sortBy) {
        try {
            let data = await Data.getJobsForSOPs(sortBy);
            vm.jobsForSOPs = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getSOPSignOffs = async function (jobID) {
        try {
            let data = await Data.getSOPSignOffs(jobID);

            vm.SOPSignOffs = data.data.data;
            vm.userToSignOff = vm.SOPSignOffs.users[vm.userKey];
            vm.data.sopToRead = vm.SOPSignOffs.SOPs[vm.sopKey];
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.signOffSOP = async function (signOffType, jobID, SOPID, userID, userName, password, digitalSignature) {
        try {
            await Data.signOffSOP(signOffType, jobID, SOPID, userID, userName, password, digitalSignature);
            await vm.getSOPSignOffs(jobID);
            alert('Record Successfully added');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert(e.data.message);
        }

    }


    vm.getDataForDataFiltering = async function (queryType) {
        try {
            vm.dataForDataFiltering = {}
            vm.selectedRows = {}

            if (queryType === 'all') {
                vm.dataFilteringData.filterDataToQuery = {}
                vm.dataFilteringSelect = {}
                vm.dataFilteringData.currentPage = 1;
            }

            let data = await Data.getDataForDataFiltering(vm.dataFilteringData, 'fieldSlugs');
            if (queryType === 'all') {
                vm.originalDataForDataFiltering = data.data;
            }

            vm.dataForDataFiltering = data.data;
            vm.selectedRows = {}
            vm.dataForDataFilteringCount = data.data.data.countLimitInfo.scans_counted;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    vm.openFileLocally = function (fileName, data, targetOption) {
        try {
            let browserAvailableExtensions = [
                'AVI', 'CSS', 'GIF', 'HTML', 'HTM', 'JPG', 'JPEG', 'MID',
                'MIDI', 'MP3', 'MPG', 'MPEG', 'MOV', 'QT', 'PDF', 'PNG', 'RAM', 'RAR', 'TIFF', 'TXT',
                'WAV', 'ZIP'];

            let fileAlreadyDownloaded = false;

            let ext = fileName.substr(fileName.lastIndexOf('.') + 1);
            ext = ext.toUpperCase();
            let url = $window.URL || $window.webkitURL;
            vm.fileUrl = url.createObjectURL(data);
            let a = document.createElement("a");
            // we have to choose the download option if the file does not open in a browser tab
            if (targetOption == 'download' || !browserAvailableExtensions.includes(ext)) {
                a.href = vm.fileUrl;
                a.download = fileName;
                a.target = '_self';
                document.body.appendChild(a);
                a.click();
                fileAlreadyDownloaded = true;
            }
            if (targetOption == 'new-tab' && !fileAlreadyDownloaded) {
                a.href = vm.fileUrl;
                let tab = window.open();
                tab.location.href = vm.fileUrl;
                a.download = fileName;
                a.target = '_blank';
                document.body.appendChild(a);
            }


        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }

    vm.showImageOnScreen = function (fileName, data) {
        let ext = fileName.substr(fileName.lastIndexOf('.') + 1);

        let url = $window.URL || $window.webkitURL;
        vm.imageURL = url.createObjectURL(data);

        $scope.$apply();
    }

    vm.playSound = async function (sound) {

        let snd = undefined;
        switch (sound) {
            case 'error':
                snd = new Audio("/resources/error.mp3");
                break;
            case 'warning':
                snd = new Audio("/resources/warning-sound.mp3");
                break;
        }

        if (snd !== undefined) {
            await snd.play();
            $scope.$apply();
        }
    }

    vm.scrollTop = function () {
        $('html, body').animate({
            scrollTop: "0px"
        }, 800);
    }

    vm.scrollTo = function (tag) {
        let aTag = $("a[name='" + tag + "']");
        $("html, body").animate({scrollTop: aTag.offset().top - 150},
            "slow");
    }

    vm.addAutomatedReport = async function () {
        try {
            await Data.addAutomatedReport(vm.newAutomatedReport);
            await vm.getAutomatedReports();
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateAutomatedReport = async function () {
        try {
            await Data.updateAutomatedReport(vm.reportToEdit);
            await vm.getAutomatedReports();
            alert('Automated Report succesfully updated.');
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getAutomatedReports = async function () {
        try {
            let data = await Data.getAutomatedReports(vm.id);
            vm.automatedReports = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteAutomatedReport = async function (id) {
        try {
            await Data.deleteAutomatedReport(id);
            await vm.getAutomatedReports();
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.generateBarcodeSheetFromExcelUpload = async function () {
        try {
            let excelDataFile = vm.excelData;
            vm.updating = true;
            let data = await Data.generateBarcodeSheetFromExcelUpload(excelDataFile);
            vm.updating = false;
            vm.openExcelFile('barcode-data', data.data);
            $scope.$apply();
        } catch (e) {
            console.log(e);
            vm.updating = false;
            alert('Something went wrong, please check the browser console to see the error');
        }

    }

    vm.getPageLinks = async function (stateName = null) {
        try {
            if (stateName == null) {
                stateName = vm.stateName;
            }
            let data = await Data.getPageLinks(stateName);
            vm.pageSectionsAndLinks = data.data.data;
            angular.forEach(vm.pageSectionsAndLinks, function (section, index) {
                angular.forEach(section.links, function (link, linkIndex) {
                    if (link.angular_ui_state_params != null) {
                        link.ui_sref = link.angular_ui_state + '(' + link.angular_ui_state_params + ')';
                    } else {
                        link.ui_sref = link.angular_ui_state;
                    }

                });
            });
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getRMAs = async function (searchTerm, offSet) {
        try {
            if (typeof (searchTerm) == 'undefined') {
                searchTerm = 'cartolytics-all';
            }
            let data = await Data.getRMAs(searchTerm, offSet);
            vm.rmas = data.data.rmas;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.searchRMATrigger = async function (e) {
        try {
            if (e.keyCode == 13) {
                await vm.getRMAs(vm.rmaSearch, vm.offSet);
                $scope.$apply();
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateRMADetails = async function (id) {
        try {
            await Data.updateRMADetails(vm.rmaDetailsToUpdate);
            alert('RMA Details Successfully Updated');
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.updateCustomerDetails = async function () {
        try {
            await Data.updateCustomerDetails(vm.customerID, vm.customerData);
            alert('Customer Information Updated Successfully');
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteRMA = async function (rmaNumber, rmaKey) {
        try {
            await Data.deleteRMA(rmaNumber);
            await vm.getRMAs(vm.rmaSearch, vm.offSet);

            alert('RMA Successfully Deleted');
            vm.showMoreRMADetails[rmaKey] = false;
            vm.rmaDetailsToUpdate = {}
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert(e.data.error);
        }

    }


    vm.getJobStatuses = async function () {
        try {
            let data = await Data.getJobStatuses();
            vm.jobStatuses = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.getAllRoles = async function () {
        try {
            let data = await Data.getAllRoles();
            vm.allRoles = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }


    vm.getListOfDatesInMonth = function (month) {
        try
        {
            vm.datesInMonth = [];

            if (month != 'Any Month') {
                vm.numberOfDaysInMonth = moment('2019-' + month, "YYYY-MM").daysInMonth();
            } else {
                vm.numberOfDaysInMonth = 31;
            }

            for (let i = 1; i <= vm.numberOfDaysInMonth; i++) {
                vm.datesInMonth.push(i.toString());
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.addAlert = async function () {
        try {
            await Data.addAlert(vm.alertData);
            await vm.getAlerts();
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    vm.updateAlert = async function (id) {
        try {
            await Data.updateAlert(id, vm.alertDataToUpdate);
            await vm.getAlerts();
            alert('Alert successfully updated');

            vm.updateAlertPopUp = false;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteAlert = async function (id) {
        try {
            await Data.deleteAlert(id);
            await vm.getAlerts();
            vm.updateAlertPopUp = false;

        } catch (e) {
            console.log(e);
            vm.updateAlertPopUp = false;
            alert(e.data.error);
        } finally {
            $scope.$apply();
        }

    }

    vm.getAlerts = async function () {
        try {
            let data = await Data.getAlerts();
            vm.alerts = data.data.data;
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    vm.getAlertData = async function (id) {
        try {
            let data = await Data.getAlertData(id);
            vm.alertDataToUpdate = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getStationsWithFileFields = async function () {
        try {
            let data = await Data.getStationsWithFileFields();
            vm.stationsWithFileFields = data.data.data;

        } catch (e) {
            console.log(e);
        }

        $scope.$apply();

    }

    vm.getDataForFileManager = async function (scanStationID) {
        try {
            let data = await Data.getDataForFileManager(scanStationID);
            vm.dataForFileManager = data.data;

        } catch (e) {
            console.log(e);
        }

        $scope.$apply();

    }

    vm.downloadSelectedFiles = async function () {
        try {
            vm.loadingData['downloading-selected-files'] = true;
            vm.selectedFiles = [];
            angular.forEach(vm.fileDownloadList, function (value, index) {
                if (value == 1) {
                    vm.selectedFiles.push(index);
                }
            });

            let data = await Data.downloadSelectedFiles(vm.subDomain, vm.selectedFiles, vm.stationIDToLookUp);
            let blob = data.data;
            let zipBlob = new Blob([blob], {type: "application/zip"});
            let url = $window.URL || $window.webkitURL;
            vm.fileUrl = url.createObjectURL(zipBlob);

            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = 'cartolytics-files-download.zip';
            //a.download = 'test';

            a.click();
            vm.loadingData['downloading-selected-files'] = false;

        } catch (e) {
            console.log("ERROR", e);
            throw e;
        }

        $scope.$apply();

    }


    vm.removeFieldsNotInThisStation = function (fields) {
        try {
            let fieldsArray = [];
            angular.forEach(fields, function (value) {
                fieldsArray.push(value.field_slug);
            });

            angular.forEach(vm.summaryFieldsToReport, function (value, index) {
                if (!fieldsArray.includes(index)) {
                    delete (vm.summaryFieldsToReport[index]);
                }
            });

        } catch (e) {
            console.log(e);
        }
    }

    vm.addFilterData = function (fieldSlug, fieldData) {
        try {
            if (typeof (vm.dataFilteringData.resultLimit) == 'undefined') {
                vm.dataFilteringData.resultLimit = 10;
            }

            if (typeof (vm.dataFilteringData.filterDataToQuery) == 'undefined') {
                vm.dataFilteringData.filterDataToQuery = {}
            }
            if (typeof (vm.dataFilteringData.filterDataToQuery[fieldSlug]) == 'undefined') {
                vm.dataFilteringData.filterDataToQuery[fieldSlug] = [];
            }
            if (fieldData === "ignore") {
                angular.forEach(vm.dataFilteringData.filterDataToQuery, function (value, index) {
                    if (index === fieldSlug) {
                        delete vm.dataFilteringData.filterDataToQuery[index];
                    }
                });
            } else {
                vm.dataFilteringData.filterDataToQuery[fieldSlug].push(fieldData);
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.removeFilter = function (fieldSlug, fieldData, fieldKey) {
        try {
            angular.forEach(vm.dataFilteringData.filterDataToQuery[fieldSlug], function (value, index) {
                if (fieldData === value) {
                    vm.dataFilteringData.filterDataToQuery[fieldSlug].splice(index, 1);
                    if (vm.dataFilteringData.filterDataToQuery[fieldSlug].length <= 0) {

                        delete vm.dataFilteringData.filterDataToQuery[fieldSlug];

                        if (vm.dataFilteringSelect == null) {
                            vm.dataFilteringSelect = {};
                        }

                        vm.dataFilteringSelect[fieldKey] = 'ignore';
                    }
                }
            });

        } catch (e) {
            console.log(e);
        }

    }

    vm.dataFilteringNextPage = function (nextPage) {
        try {
            vm.dataFilteringData.currentPage = Data.dataNextPage(nextPage, vm.dataFilteringData.currentPage);
        } catch (e) {
            console.log(e);
        }
    }

    vm.jobsNextPage = async function (nextPage) {
        try {
            if (nextPage === 1) {
                if ((vm.currentPage * vm.resultLimit) < (vm.upperLimit)) {
                    vm.currentPage = Data.dataNextPage(nextPage, vm.currentPage);
                    vm.keepASCD_DESC_Settings = true;
                    await vm.getUsersJobs(vm.sortBy, vm.jobTypeToFilterBy);

                }
            } else if (nextPage === 0) {
                if (vm.currentPage !== 1) {
                    vm.currentPage = Data.dataNextPage(nextPage, vm.currentPage);
                    vm.keepASCD_DESC_Settings = true;
                    await vm.getUsersJobs(vm.sortBy, vm.jobTypeToFilterBy);
                }
            }

            $scope.$apply();


        } catch (e) {
            console.log(e);
        }
    }

    vm.triggerDataFilteringAction = async function (stationName) {
        try {
            if (vm.dataFilteringAction === 'send-email-alert') {
                vm.showEmailAlertPopUp = true;
            }

            if (vm.dataFilteringAction === 'export-to-excel') {
                let data = await Data.getReportByStationDataIDs(vm.selectedRows);
                await vm.openExcelFile(stationName, data.data);
                $scope.$apply();

            }

        } catch (e) {
            console.log(e);
            alert('An Error has occurred and this report has failed to generate. Please contact the administrator.');
        }
    }

    vm.selectAllDataFilteringRows = function () {
        try {
            $('.selected-row-checkbox').trigger('click');
        } catch (e) {
            console.log(e);
        }
    }

    vm.getUniqueModuleSlug = function () {
        try {
            vm.stateUniqueModuleSlug = $state.params.uniqueModuleSlug;
        } catch (e) {
            console.log(e);
        }
    }

    vm.handleClick = function (msg) {
        try {
            Data.addDataTransistion(msg);
        } catch (e) {
            console.log(e);
        }
        $scope.$apply();
    }

    vm.setLinkedJob = function (linkedJob) {
        try {
            compliance.setLinkedJob(linkedJob);
        } catch (e) {
            console.log(e);
        }
    }

    vm.toTrusted = function (htmlCode) {
        try
        {
            let elementToReturn = '';

            // some integers and floats a failing, we will do a try/catch in case anything unusual is fed in here
            if(htmlCode != null){
                elementToReturn = htmlCode.toString();
            }

            return $sce.trustAsHtml(elementToReturn);

        } catch(e) {
            console.log(e)
        }
    }

    vm.generateStationFromAnotherStationID = async function (originalStationID, newModuleId) {
        try {
            await Data.generateStationFromAnotherStationID(originalStationID, newModuleId, vm.replicateFieldRoles, vm.replicateUserAccess, vm.replicateRules);
            await vm.getScanStations();
            $scope.$apply();

            alert('Successfully Imported');

        } catch (e) {
            console.log(e);
        }
    }

    vm.signInUser = function () {
        try {
            // TODO: check this method
            Data.signInUser();
        } catch (e) {
            console.log(e);
        }
        $scope.$apply();

    }

    vm.updateSectionName = async function (newName, sectionID) {
        try {
            await Data.updateSectionName(newName, sectionID);
            await vm.getScanStations();
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }


    }

    vm.createJobStatus = async function (title = '', instanceID, excludeStatus = 0,
                                         defaultStatus = 0) {
        try {
            if (title !== '') {
                let data = await Data.createJobStatus(title, instanceID, excludeStatus, defaultStatus);
                if (data.status === 200) {
                    await vm.getJobStatuses();
                    vm.createNewJobStatus = false;

                }
                $scope.$apply();

            } else {
                alert('Module Status cannot be empty');
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteJobStatus = async function (deleteJobStatus) {
        try {
            let data = await Data.deleteJobStatus(deleteJobStatus);
            if (data.status === 200) {
                await vm.getJobStatuses();
                $scope.$apply();

            }
        } catch (e) {
            console.log(e);
        }

    }

    vm.convertJobToModule = async function (jobID) {
        try {
            await Data.convertJobToModule(jobID);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.getTemplateFileList = async function () {
        try {
            let data = await Data.getTemplateFileList();
            vm.templateFileList = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteModule = async function () {
        try {
            let data = Data.deleteModule(vm.id);
            alert('Module now deleted, you will now be redirected to the home page');
            $state.go('home');
        } catch (e) {
            console.log(e);
        }
    }

    vm.addDigitalSignature = async function () {

        const data = vm.signaturePad.toDataURL('image/png');

        try {
            await Data.addDigitalSignature(vm.id, vm.userID, data, vm.signoffItem);
            await vm.getDigitalSignaturePreview();
            alert('Thanks, you have succesfully updated your signature');
            $scope.$apply();
        } catch (e) {
            console.log(e);
            alert('Error has occured, please check browser console to view error');
        }
    };

    vm.startDigitalSignature = function () {
        var canvas = document.querySelector("canvas");
        vm.signaturePad = new SignaturePad(canvas);
        // Rebinds all event handlers
        vm.signaturePad.on();
    }

    vm.dateOptions = {
        formatYear: 'yy',
        maxDate: new Date(2020, 5, 22),
        minDate: new Date(),
        startingDay: 1
    };

    vm.format = 'yyyy-MM-DD 00:00:00';

    vm.setFromDate = function () {
        if (vm.requestedReportData == null) {
            vm.requestedReportData = {};
        }
        vm.requestedReportData.reportFromDate = moment(vm.datePickerFromDate).format('yyyy-MM-DD 00:00:00');
    }

    vm.tinymceOptions = {
        invalid_elements: "input,textarea,select,option",
        paste_data_images: true,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons textcolor',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        content_css: [
            '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
            '//www.tiny.cloud/css/codepen.min.css',
            '/css/custom-tiny-mce.css'
        ],
        link_list: [
            {title: 'My page 1', value: 'http://www.tinymce.com'},
            {title: 'My page 2', value: 'http://www.moxiecode.com'}
        ],
        image_list: [
            {title: 'My page 1', value: 'http://www.tinymce.com'},
            {title: 'My page 2', value: 'http://www.moxiecode.com'}
        ],
        image_class_list: [
            {title: 'None', value: ''},
            {title: 'Some class', value: 'class-name'}
        ],
        importcss_append: true,
        file_picker_callback: function (callback, value, meta) {
            /* Provide image and alt text for the image dialog */
            if (meta.filetype === 'image') {
                callback('https://www.google.com/logos/google.jpg',
                    {alt: 'My alt text'});
            }

            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === 'media') {
                callback('movie.mp4',
                    {
                        source2: 'alt.ogg',
                        poster: 'https://www.google.com/logos/google.jpg'
                    });
            }

            /* Provide file and text for the link dialog */
            if (meta.filetype === 'file') {
                callback('https://www.google.com/logos/google.jpg',
                    {text: 'My text'});
            }
        },
        templates:
            [
                {
                    title: 'New Table',
                    description: 'creates a new table',
                    content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
                },
                {
                    title: 'Starting my story',
                    description: 'A cure for writers block',
                    content: 'Once upon a time...'
                },
                {
                    title: 'New list with dates',
                    description: 'New List with dates',
                    content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
                }
            ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: 600,
        image_caption: true,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_drawer: 'sliding',
        contextmenu: "link image imagetools table",
    };

    vm.getChildInstanceModuleAccess = async function () {
        let data = await Data.getChildInstanceModuleAccess(vm.id);

        vm.moduleAvailableToChild = [];

        angular.forEach(data.data.data, function (row) {
            vm.moduleAvailableToChild[row.shared_with_cartolytics_customer_id] = 1;
        });

        $scope.$apply();
    }

    vm.updateChildInstanceModuleAccess = async function (instanceId, accessValue) {
        let data = await Data.updateChildInstanceModuleAccess(vm.id, instanceId, accessValue);
        alert('Instance Updated');
    }

    vm.getBlocLinkUrl = (moduleSlug: string, stationID: string) => {
        return $state.href('bloc', {uniqueModuleSlug: moduleSlug, stationId: stationID})
    }

    /**
     * Import the module using the zip file provided in the importModuleFile variable
     */
    vm.importModule = async function () {
        try {

            const file = vm.importModuleFile;

            await Data.importModule(file);

            alert('File uploaded');

        } catch (e) {
            console.log(e);
            alert(e.data.message)
        }

    }
}
